import { functions, databases, Query, account } from '../config';

const getUserData = async () => {
  try {
    const response = await account.get();
    console.log('ss'+response)
    if(response['$id'] == "" || response['$id'] == undefined){
          await account.createAnonymousSession();
    }
    return response;
  } catch (e) {
    console.log('eee'+e.message);
    await account.createAnonymousSession();
  }
};

const saveSession = async (data) => {
  try {
    // console.log('data' + JSON.stringify(data))
    return functions.createExecution('saveSession', JSON.stringify(data));
  } catch (e) {
    console.error(e.message);
  }
};
const plantTree = async (data) => {
  try {
    // console.log('data' + JSON.stringify(data))
    return functions.createExecution('checkAndOrderTree', JSON.stringify(data), false);
  } catch (e) {
    console.error(e.message);
  }
};
const getHistory = async (data) => {
  
  try {
    // console.log('data' + JSON.stringify(data))
    const resp = await functions.createExecution('getHistory', JSON.stringify(data), false)
    // try {
    //   let result = await functions.getExecution('getHistory', resp.$id)
      // console.log(resp)
    //   while (result.status != 'done'){
    //       result = await functions.getExecution('getHistory', resp.$id)
    //   }
    //   return result.response;
    // } catch (e) {
    //   console.error(e.message);
    // }
    return resp.response
  } catch (e) {
    console.error(e.message);
  }

};
const getHistoryRedeem = async (uid) => {
  try {
    // console.log(uid);
    return databases.listDocuments('630700ce257cece07931', [
      Query.equal('mondayAccId', uid)
    ]);
  } catch (e) {
    console.error('getH :' + e.message);

  }
};
const getTotalRedeemId = async (data) => {
  try {
    // console.log('data' + JSON.stringify(data))
    const resp = await functions.createExecution('getRemainPlantLimit', JSON.stringify(data), false)
    return resp.response;
  } catch (e) {
    console.error(e.message);
  }
};
const getTotalRedeem = async (id) => {
  try {
    const resp = await functions.getExecution('getRemainPlantLimit', id)
    // console.log(JSON.stringify(resp))
    return resp.response;
  } catch (e) {
    console.error(e.message);
  }
};


const getReadyTrees = async () => {
  try {
    // console.log(uid);
    return databases.getDocument('630700dfbd24068ee99c', '63072745c7c2da62380c');
  } catch (e) {
    console.error('getRT :' + e.message);

  }
};
const getQuotes = async () => {
  try {
    // console.log(uid);
    return databases.listDocuments('630700bc9d781ded035a');
  } catch (e) {
    console.error('getRT :' + e.message);

  }
};

export {
  saveSession,
  getHistory,
  getUserData,
  getQuotes,
  plantTree,
  getReadyTrees,
  getTotalRedeemId,
  getTotalRedeem,
  getHistoryRedeem
}