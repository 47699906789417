import React, { useState, useEffect } from "react";
import "./timer.sass";
import Break from "./components/Break/Break";
import Session from "./components/Session/Session";
import Controls from "./components/Controls/Controls";
import ThemeToggle from "./components/ThemeToggle/ThemeToggle";
import { useStateValue } from "./contexts/stateProvider";
import { useClockify } from "./hooks/useClockify";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Dropdown, List, ListItem, Avatar, Loader } from "monday-ui-react-core";
import "react-circular-progressbar/dist/styles.css";
import Lottie from "react-lottie";
import plant0 from "./lotties/plant0.json";
import plant1 from "./lotties/plant1.json";
import plant2 from "./lotties/plant2.json";
import break0 from "./lotties/break.json";
import break2 from "./lotties/break2.json";
import rewardLottie from "./lotties/reward.json";
import Stats from "./components/Stats";
import Logo from "./components/Logo";
import Modal from "./components/Modal";
import Info from "./components/Info";
import { account } from "./config";
import { saveSession, getHistory, getReadyTrees, getTotalRedeem, getTotalRedeemId, getHistoryRedeem, getQuotes, plantTree } from "./actions"
import ClimateQuotes from "./components/ClimateQuotes"

const Timer = ({ monday }) => {
  const [{ timerLabel, progValue, sessionValue }] = useStateValue();
  const [checked, setChecked] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );
  let [authed, setAuthed] = useState(null)
  let [isSettingOpen, setIsSettingOpen] = useState(false);
  let [isReportOpen, setIsReportOpen] = useState(false);
  let [isTreeOpen, setIsTreeOpen] = useState(false);
  let [isOfferOpen, setIsOfferOpen] = useState(false);
  let [isTaskOpen, setIsTaskOpen] = useState(false);
  let [isRewardOpen, setIsRewardOpen] = useState(false);
  let [isPro, setIsPro] = useState(false);
  let [isOrdering, setIsOrdering] = useState(false);
  let [isTreeDetailsOpen, setIsTreeDetailsOpen] = useState(false);
  let [selectedBoard, setSelectedBoard] = useState({});
  let [selectedColumn, setSelectedColumn] = useState({});
  let [selectedDoneValue, setSelectedDoneValue] = useState("");
  let [doneValueList, setDoneValueList] = useState([]);
  let [boards, setBoards] = useState([]);
  let [statsData, setStatsData] = useState([]);
  let [selectedTask, setSelectedTask] = useState({});
  let [history, setHistory] = useState({});
  let [quotes, setQuotes] = useState({});
  let [redeemHistory, setRedeemHistory] = useState({});
  let [pears, setPears] = useState();
  let [co2, setCO2] = useState();
  let [userName, setUserName] = useState(null);
  let [remainTree, setRemainTree] = useState(null);
  let [readyTrees, setReadyTrees] = useState([]);
  let [selectedTree, setSelectedTree] = useState({});
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();

  useEffect(() => {
    const fetchData = async () => {
      const isAuth = localStorage.getItem('cookieFallback')
      
      if (isAuth == null) {
        // console.log('1'+isAuth)
        return await account.createAnonymousSession()
      }else {
        // console.log('2'+isAuth)
        return isAuth
      }
    }

    fetchData().then((res) => {
      // console.log('authed'+res)
      setAuthed(res)
    })

  }, [])

  async function fetchBoard() {
    // TODO: set up event listeners
    await monday.get("sessionToken").then(async (res) => {
      let data = { "sessionToken": res.data }
      const listHistory = await getHistory(data);
      // console.log(listHistory)
      setHistory(JSON.parse(listHistory))

    });

    await monday.get("context").then((res) => {
      // console.log(JSON.stringify(res));
      let listBoard = res.data.boardIds;
      let uid = res.data.user.id;
      let getPro = res.data.hasOwnProperty('subscription') ? res.data.subscription.plan_id === 'pro_plan' && !res.data.subscription.is_trial : false
      setIsPro(getPro)

      return [listBoard, uid];
    }).then(async (datas) => {
      // console.log(brds[1],brds[0])


      const listRedeemHistory = await getHistoryRedeem(datas[1]);
      const listQuotes = await getQuotes();
      // console.log(listQuotes);
      setQuotes(listQuotes)
      setRedeemHistory(listRedeemHistory)

      let userId = { "mondayAccId": datas[1] }
      getTotalRedeemId(userId).then((resp) => {
        let parseTree = JSON.parse(resp).response
        // console.log(parseTree)
        setRemainTree(parseTree)
      }).catch((err) => console.log(err))

      await monday
        .api(
          `query {
        boards (ids: [${datas[0]}]) {
          id
          name
          items {
            id
            name
            column_values {
              id
              title
              text
          }
          }
        }
      }`
        )
        .then((resp) => {
          // console.log(resp);
          let data = resp.data.boards || [];
          setBoards(data)
        });

      await monday
        .api(
          `query {
    users (ids: [${datas[1]}]) {
    email
        account {
            name
        }
    }
}`
        )
        .then((resp) => {
          // console.log(resp);
          let data = resp.data.users || [];
          setUserName(data[0])
        });
    });
  }

  useEffect(() => {
    if(authed != null){
      fetchBoard()
    getReadyTrees().then((dataResp) => {
      let trees = dataResp.trees
      setReadyTrees(JSON.parse(trees))
    }).catch((err) => { console.log(err) })
    }
    

  }, [authed])


  // useEffect(() => {

  //   console.log(readyTrees)


  // }, [readyTrees])

  useEffect(() => {
    // console.log(redeemHistory)
    if(authed != null){
    if (history.hasOwnProperty('total') && redeemHistory.hasOwnProperty('total')) {
      // console.log(history)
      let currPears = 0;
      let total = 0;
      history.documents.map((val, i) => {
        currPears += val.duration
      })
      redeemHistory.documents.filter((val) => val.redeemDate == `${month}-${year}`).map((val, i) => {
        currPears -= val.pearsRedeem
      })
      redeemHistory.documents.map((val, i) => {
        total += val.coOffset
      })
      setCO2(total)

      let resultArr = [];
      let valueArr = [...new Set(history.documents.map(function (item) { return item.fullDate }))];
      // console.log(valueArr);
      valueArr.map((name) => {
        let crrItem = {
          fullDate: name,
          duration: 0
        }
        history.documents.map((item) => {
          //   console.log(item.name === name);
          if (item.fullDate === name) {
            crrItem.duration += item.duration
          }
        });
        resultArr.push(crrItem)
      })

      setStatsData(resultArr.reverse())
      setPears(currPears >= 0 ? currPears : 0)

    }
  }

  }, [authed, history, redeemHistory])


  useEffect(() => {
    if(authed != null){
    if (selectedBoard.hasOwnProperty('value') && selectedColumn.hasOwnProperty('value')) {
      let valDoneStatus = [];
      boards[selectedBoard.value].items.map((val, idx) => {
        if (valDoneStatus.indexOf(val.column_values[selectedColumn.value].text) == -1) {
          valDoneStatus.push(val.column_values[selectedColumn.value].text);
        }
      })
      setDoneValueList(valDoneStatus);
    }
  }

  }, [authed, selectedColumn])

  // useEffect(()=>{
  //   console.log(progValue)
  // },[progValue])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:
      timerLabel == "Break"
        ? break0
        : progValue > 0 && progValue < 30
          ? plant0
          : progValue > 30 && progValue < 80
            ? plant1
            : plant2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const rewardOptions = {
    loop: true,
    autoplay: true,
    animationData: rewardLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function closeModal(modal) {
    if (modal == "setting") {
      setIsSettingOpen(false);
    } else if (modal == "reward") {
      setIsRewardOpen(false);
    } else if (modal == "report") {
      setIsReportOpen(false);
    } else if (modal == "tree") {
      setIsTreeOpen(false);
    } else if (modal == "offer") {
      setIsOfferOpen(false);
    } else if (modal == "task") {
      setIsTaskOpen(false);
    } else if (modal == "treeDetails") {
      setIsTreeDetailsOpen(false);
    } else if (modal == "break") {
      setIsTreeDetailsOpen(false);
    }
  }

  async function openModal(modal) {
    if (modal == "setting") {
      setIsSettingOpen(true);
    } else if (modal == "reward") {
      setIsRewardOpen(true);
    } else if (modal == "report") {
      setIsReportOpen(true);
    } else if (modal == "tree") {

      setIsTreeOpen(true);

    } else if (modal == "offer") {
      setIsOfferOpen(true);
    } else if (modal == "task") {
      setIsTaskOpen(true);
    } else if (modal == "treeDetails") {
      setIsTreeDetailsOpen(true);
    } else if (modal == "break") {
      setIsTreeDetailsOpen(true);
    }
  }

  function selectTask(val) {
    setSelectedTask({
      name: val.name,
      id: val.id
    });
    closeModal('task')
  }

  function selectTree(val) {
    // console.log('asdas')
    setSelectedTree(val)
    openModal("treeDetails")
  }
  const clockifiedValue = useClockify();

  function setNotif(timeout) {
    //     monday.execute("notice", { 
    //    message: "I'm a success message",
    //    type: "success", // or "error" (red), or "info" (blue)
    //    timeout: timeout,
    // });
    const rewardVal = sessionValue;
    monday.get("sessionToken").then((res) => {
      let data = { "taskName": selectedTask.name, "duration": rewardVal, "itemId": selectedTask.id, "isWork": timerLabel != "Break", "sessionToken": res.data }

      saveSession(data).then((resp) => {
        // console.log('saveFunction :' + JSON.stringify(resp))
      }).catch((err) => console.log(err))
      // console.log(res)

    });
    // console.log('reward:'+rewardVal)

    openModal('reward');

  }
  function openOffer() {
    // console.log(selectedColumn.id)
    monday.execute('openPlanSelection', { isInPlanSelection: false });

  }
  function updateTaskStatus() {
    // console.log(selectedColumn.id)
    monday.execute('openItemCard', { itemId: selectedTask.id }).then((res) => {
      fetchBoard()
    })

  }
  async function orderTree() {
    // console.log(selectedColumn.id)
    setIsOrdering(true)
    await monday.get("sessionToken").then(async (res) => {
      let data = { "sessionToken": res.data, "idTree": selectedTree.id, "name": userName.account.name, "email": userName.email }

      await plantTree(data).then((resp) => {
        // console.log('plantTree :' + JSON.stringify(resp))
        setIsOrdering(false)
        fetchBoard()
      }).catch((err) => console.log(err))
      // console.log(res)
      setIsOrdering(false)
    });
  }

  if (!history.hasOwnProperty('total')) {
    return <div style={{ display: 'flex', justifyContent: 'center' }}><Loader size={Loader.sizes.MEDIUM} color={Loader.colors.PRIMARY} /></div>
  }

  return (
    <>
      <div
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="timer__maintitle">
          <Logo checked={checked} />
        </div>
        <div
          className="nav-container"
          style={{ display: "flex", gap: "0.3em" }}
        >
          <div className="nav-button" onClick={() => openModal("report")}>
            📊
          </div>
          <div className="nav-button" onClick={() => openModal("setting")}>
            ⚙
          </div>
          <div
            className="nav-button"
            style={{
              padding: "0.5em",
            }}
            onClick={() => openModal("tree")}
          >
            🍐{pears || ' - '}
          </div>
        </div>
      </div>
      <Info co2Offset={co2} redeemTrees={redeemHistory} />
      <div className="timer">

        <div
          style={{
            width: 195,
            height: 195,
            display: "flex",
            marginTop: "-2em",
          }}
        >
          <CircularProgressbarWithChildren
            value={progValue}
            strokeWidth={4}
            strokeTrail
            styles={{
              path: {
                stroke: checked ? "#757575" : "#41d09d",
              },
              trail: {
                stroke: "#ebf3f4",
              },
            }}
          >
            <div id="time-left" className="timer__time">
              <Lottie options={defaultOptions} height={150} width={150} />
            </div>
          </CircularProgressbarWithChildren>
        </div>

        <Controls task={selectedTask} setNotif={setNotif} />
        <h1 style={{ margin: 0 }}>
          {clockifiedValue[0] + ":" + clockifiedValue[1]}
        </h1>
        {selectedTask.hasOwnProperty('id') ? <div className="selected-task" onClick={progValue == 0 && timerLabel == "Session" ? () => openModal("task") : () => { }}>{selectedTask.name}</div> :
          <div className="select-task" onClick={() => openModal("task")}>
            Select Task
          </div>
        }
        <div className="timer__controllers">
          <Break />
          <Session />
        </div>
      </div>
      <Modal
        show={isSettingOpen}
        setClose={() => closeModal("setting")}
        title="Setting"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>Dark Mode</p>
          <ThemeToggle checked={checked} setChecked={setChecked} />
        </div>
      </Modal>
      <Modal
        show={isReportOpen}
        setClose={() => closeModal("report")}
        title="Report"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {isPro ? <Stats data={statsData} /> : <div><span className="proButton" onClick={() => openOffer()}>🔒 upgrade to pro</span></div>}
        </div>
      </Modal>


      <Modal
        show={isTreeOpen}
        setClose={() => closeModal("tree")}
        title="Plant a Real Tree"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>convert your pears to the real tree : ({remainTree}/3 🌳)</p>
          <List
            style={{
              height: "162px",
              width: "-webkit-fill-available",
              marginTop: "1em",
            }}
          >

            {readyTrees.length != 0 && readyTrees.map((val, idx) => {
              return <ListItem key={idx} size={ListItem.sizes.LARGE} onClick={() => selectTree(val)}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: "-webkit-fill-available" }}>
                  <div style={{ display: 'flex', gap: '0.5em' }}>
                    <Avatar size={Avatar.sizes.LARGE} src={val.image} type={Avatar.types.IMG} square ariaLabel="Hadas Fahri" />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <strong>{val.name}</strong>
                      CO2 Offset: {val.life_time_CO2}kg
                    </div>
                  </div>
                  <div>
                    🍐 {val.pears}
                  </div>
                </div>
              </ListItem>
            })}



          </List>
        </div>
      </Modal>
      <Modal
        show={isTreeDetailsOpen}
        setClose={() => closeModal("treeDetails")}
        title="Tree Details"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "justify",
            maxHeight: "350px",
            overflow: "auto"
          }}
        >
          <img src={selectedTree.image} width={250} height={200} style={{ margin: 'auto', objectFit: 'cover' }} />{
            !isPro ?
              <div style={{ alignSelf: 'center', textAlign: 'center', marginBottom: '1em' }}> <div className="select-task-disabled" style={{ marginTop: '0.5em', marginBottom: 0, width: 'fit-content', display: 'flex', alignSelf: 'center' }} onClick={() => { }}>
                Redeem 🍐{selectedTree.pears || '-'}
              </div> <span className="proButton" onClick={() => openOffer()}>🔒 pro</span></div> :
              pears < selectedTree.pears ?
                <div style={{ alignSelf: 'center', textAlign: 'center', marginBottom: '1em' }}> <div className="select-task-disabled" style={{ marginTop: '0.5em', marginBottom: 0, width: 'fit-content', display: 'flex', alignSelf: 'center' }} onClick={() => { }}>
                  🍐Pears not enough
                </div></div>
                :
                remainTree < 3 ?
                  <div className="select-task" style={{ marginTop: '0.5em', width: 'fit-content', display: 'flex', alignSelf: 'center' }} onClick={isOrdering ? () => { } : () => orderTree()}>
                    {isOrdering ? <Loader size={Loader.sizes.SMALL} /> : <div> Redeem 🍐{selectedTree.pears || '-'}</div>}
                  </div> :
                  <div style={{ alignSelf: 'center', textAlign: 'center', marginBottom: '1em' }}> <div className="select-task-disabled" style={{ marginTop: '0.5em', marginBottom: 0, width: 'fit-content', display: 'flex', alignSelf: 'center' }} onClick={() => { }}>
                    🌳 Reach Limit
                  </div></div>

          }
          <span><strong>Name : </strong>{selectedTree.name || '-'}</span>
          <span><strong>CO2 Offset : </strong>{selectedTree.life_time_CO2 || '-'}kg</span>
          <span><strong>Description : </strong>{selectedTree.desc || '-'}</span>
          <span><strong>Planter likes : </strong>{selectedTree.planter_likes || '-'}</span>
        </div>
      </Modal>
      <Modal
        show={isRewardOpen}
        setClose={() => {
          fetchBoard()
          closeModal("reward")
        }}
        title="You did it!"
      >
        <div
          style={{
            display: "flex",
            flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>You Got<span style={{ fontWeight: 800, fontSize: 20, color: 'orange' }}> 🍐{sessionValue} </span>Pears</p>
          <Lottie options={rewardOptions} height={200} width={230} />
          <p>Task :</p>
          <div className="updateTask">{selectedTask.name}</div>
          <div className="select-task" onClick={() => updateTaskStatus()}>
            Update Task Status
          </div>
        </div>
      </Modal>
      <Modal
        show={timerLabel == 'Break' && progValue > 0}
        title={`Break Session ${clockifiedValue[0]} : ${clockifiedValue[1]}`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {quotes.hasOwnProperty('total') && <ClimateQuotes data={quotes.documents} lotte={{ ...defaultOptions, animationData: break2 }} />}
        </div>
      </Modal>
      <Modal
        show={isTaskOpen}
        setClose={() => closeModal("task")}
        title="Select Task to Do"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "-webkit-fill-available",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: '0.5em'
            }}
          >
            <Dropdown
              className="dropdown-stories-styles_big-spacing"
              onChange={(val) => setSelectedBoard(val)}
              noOptionsMessage={() => 'No Boards'}
              clearable={false}
              options={boards.length != 0 ? boards.map((val, idx) => {
                return {
                  label: val.name,
                  value: idx
                }
              }) : []}
              placeholder="Select Board"
            />
            {selectedBoard.hasOwnProperty('value') && <Dropdown
              className="dropdown-stories-styles_big-spacing"
              onChange={(val) => setSelectedColumn(val)}
              noOptionsMessage={() => 'No Column'}
              clearable={false}
              options={selectedBoard.hasOwnProperty('value') && boards[selectedBoard.value].items[0].column_values != 0 ? boards[selectedBoard.value].items[0].column_values.map((val, idx) => {
                return {
                  label: val.title,
                  id: val.id,
                  value: idx
                }
              }) : []}
              placeholder="Definition of Done Column"
            />}
            {selectedColumn.hasOwnProperty('value') && <Dropdown
              className="dropdown-stories-styles_big-spacing"
              onChange={(val) => {
                setSelectedDoneValue(val)
              }}
              noOptionsMessage={() => 'No Value of Done'}
              clearable={false}
              options={doneValueList.map((val, idx) => {
                return {
                  label: val,
                  value: val
                }
              })}
              placeholder="Value of Done Status"
            />}
            <List
              style={{
                height: "162px",
                width: "-webkit-fill-available",
                marginTop: "1em",
              }}
            >

              {selectedColumn.hasOwnProperty('value') && boards[selectedBoard.value].items.filter((val) => val.column_values[selectedColumn.value].text == selectedDoneValue.value).map((val, idx) => {
                return <ListItem onClick={() => selectTask(val)}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <strong>{val.name}</strong>
                    {val.column_values[selectedColumn.value].text}</div>

                </ListItem>
              })}



            </List>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Timer;
