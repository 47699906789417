import './break.sass'
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useStateValue } from '../../contexts/stateProvider'
import { actionTypes } from '../../reducer'

const Break = () => {

    const [{ breakValue, busyIndicator, timerLabel }] = useStateValue();
    const [state, dispatch] = useStateValue();

    const handleDecrement = () => {
        let updateSessionVal = breakValue - 1;
        let updateIncProg = 100 / (updateSessionVal * 60);
       timerLabel == "Break" ? dispatch({
            ...state,
            type: actionTypes.DECREASE_BREAK_VALUE,
            breakValue: updateSessionVal, 
            timerValue: updateSessionVal * 60,
            incProgValue: updateIncProg
        }) : dispatch({
            ...state,
            type: actionTypes.DECREASE_BREAK_VALUE,
            breakValue: updateSessionVal
        })

    }
    const handleIncrement = () => {
        let updateSessionVal = breakValue + 1;
        let updateIncProg = 100 / (updateSessionVal * 60);
        timerLabel == "Break" ? dispatch({
            ...state,
            type: actionTypes.INCREASE_BREAK_VALUE,
            breakValue:updateSessionVal,
            timerValue: updateSessionVal * 60,
            incProgValue: updateIncProg
        }) : dispatch({
            ...state,
            type: actionTypes.INCREASE_BREAK_VALUE,
            breakValue: updateSessionVal
        })
    }

    return (
        <div className='break'>
            <span id='break-label' className='break__label'>Break Length</span>
            <div className="break__controls">
                <button
                    type='button'
                    id='break-decrement'
                    className='break__controls--btn'
                    onClick={handleDecrement}
                    disabled={busyIndicator || breakValue <= 1}
                >
                    <FontAwesomeIcon icon={faMinus} />
                </button>
                <p id="break-length" className='break__length'>{breakValue}</p>
                <button
                    type='button'
                    id='break-increment'
                    className='break__controls--btn'
                    onClick={handleIncrement}
                    disabled={busyIndicator || breakValue > 59}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
        </div>
    )
}

export default Break;
