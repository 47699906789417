import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie'


const Quotes = ({ data, lotte }) => {

    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        // every 5000ms (5s)
        const timer = window.setInterval(() => {
            // `prev` will be the current value of the state; using
            // the callback here means we avoid our useEffect hook 
            // being evaluated on each state update which would be
            // bad for performance.
            setActiveIndex(prev =>
                // if we're at the end, start again at the beginning,
                // otherwise increment the index
                prev + 1 >= data.length ? 0 : prev + 1
            )
        }, 10000)

        // we return a cleanup callback that will stop the timer
        return () => {
            window.clearInterval(timer)
        }
    }, [data])

    // set the activeQuote here to avoid duplicating logic and 
    // to make it clear what is being rendered below
    const activeQuote = data[activeIndex]

    return (
        <div style={{textAlign: 'center'}}>
            <Lottie options={lotte} height={200} width={230} />
            <h4>{activeQuote.quote}</h4>
            <h5>- {activeQuote.writer} -</h5>
        </div>
    )
}

export default Quotes