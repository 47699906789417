import { Client, Functions, Account, Databases, Query } from "appwrite";

const client = new Client();

const functions = new Functions(client);

const account = new Account(client);

const databases = new Databases(client, "630700ad3d7c78ff03ee");

client
  .setEndpoint('https://growly.live/v1') // We set the endpoint, change this if your using another endpoint URL.
  .setProject('6306ff6b0611748ce8c6'); // Here replace 'ProjectID' with the project ID that you created in your appwrite installation.
// const promise = account.get();

// promise.then(async function (response) {
//     console.log(response); // Success
//   if(response['$id'] == "" || response['$id'] == undefined){
//     await account.createAnonymousSession();
//   }
  
// }, async function (error) {
//     console.log(error); // Failure
//   await account.createAnonymousSession();
// });



export { client, functions, account, databases, Query }; // Finally export the appwrite object to be used in projects.