import { useEffect, useState } from "react";
import { useStateValue } from "../contexts/stateProvider";

export function useClockify() {
  const [{ timerValue, sessionValue }] = useStateValue();
//   const [progressValue, setProgressValue] = useState(0);

//   useEffect(()=>{
//       setProgressValue((pastVal)=>setProgressValue(pastVal+incProgress))
//   },[timerValue])

  let minutes = Math.floor(timerValue / 60);
  let seconds = timerValue - minutes * 60;
//   let incProgress = 100 / (sessionValue * 60);
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
//   console.log(progressValue);
  return [minutes, seconds];
}
