import React, {useState, useEffect} from 'react';
import { Column } from '@ant-design/plots';

const Stats = ({data}) => {
  
    // useEffect(()=>{
    //   console.log(data)
    // },[data])
    let config = {
      data,
      xField: 'fullDate',
      yField: 'duration',
      xAxis: {
        label: {
          autoRotate: false,
        },
      },
      scrollbar: {
        type: 'horizontal',
      },
      animation: {
        // Configuration of the first animation
        appear: {
          animation: 'scale-in-y', // Effects of the first animation
          duration: 1000, // Duration of the first animation
        },
      }, 
      style: {
        stroke: 'red'
      },
      meta: {
        duration:{
          alias: 'minutes'
        }
      },
      minColumnWidth: 20,
    maxColumnWidth: 20,
      columnStyle: {
        fill: 'green',
        fillOpacity: 0.5,
        lineWidth:1,
        cursor: 'pointer'
      }
    };
    if ( data.length == 0 ) {
        return 'No History'
    }
    return <Column {...config}  />;
  };

  export default Stats