import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { Dropdown, List, ListItem, Avatar } from "monday-ui-react-core";
import "./info.sass";
export default function Info({co2Offset, redeemTrees}) {
  const [co2, setCO2] = useState({});
  const [temp, setTemp] = useState({});
  let [isRedeemHistoryOpen, setIsRedeemHistoryOpen] = useState(false);

  function closeModal() {
    setIsRedeemHistoryOpen(false);
  }

  function openModal() {
    setIsRedeemHistoryOpen(true);
  }
  useEffect(() => {
    fetch("https://global-warming.org/api/co2-api")
      .then((response) => response.json())
      .then((data) => {
        let datas = data.co2;
        let latestCO2 = datas[datas.length - 1];
        // console.log(latestCO2)
        setCO2(latestCO2);
      })
      .catch((err) => {
        console.log(err);
      });

    fetch("https://global-warming.org/api/temperature-api")
      .then((response) => response.json())
      .then((data) => {
        let datas = data.result;
        let latestTemp = datas[datas.length - 1];
        setTemp(latestTemp);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
    <div className="info-container">
      <div className="info-temperature" onClick={() => window.open('https://global-warming.org','_blank')}>
        <h4 style={{ margin: 0 }}>🥵 {co2.trend || '-'}ppm</h4>
        <p style={{ margin: 0, fontSize: "smaller" }}>CO2 in Earth</p>
        {/* <a
          style={{
            margin: 0,
            fontSize: "smaller",
            textDecoration: "underline",
            cursor: 'pointer',
            color: 'white'
          }}
          href="https://global-warming.org"
          target='_blank'
        >
          {co2.day}-{co2.month}-{co2.year}
        </a> */}
      </div>
      <div className="info-temperature" onClick={()=>openModal()}>
        <h4 style={{ margin: 0 }}>😎 {co2Offset || '-'}Kg</h4>
        <p style={{ margin: 0, fontSize: "smaller" }}>Your CO2 Offset</p>
        {/* <p
          style={{
            margin: 0,
            fontSize: "smaller",
            textDecoration: "underline",
          }}
        >
         All time
        </p> */}
      </div>
    </div>
      <Modal
        show={isRedeemHistoryOpen}
        setClose={() => closeModal()}
        title="Redeem History"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>total real tree you plant : ({redeemTrees.total || '-'} 🌳)</p>
          <List
            style={{
              height: "162px",
              width: "-webkit-fill-available",
              marginTop: "1em",
            }}
          >

            {redeemTrees.hasOwnProperty('total') && redeemTrees.documents.map((val, idx) => {
              return <ListItem key={idx} size={ListItem.sizes.LARGE} onClick={() => window.open(`${val.certUrl}`,'_blank')}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: "-webkit-fill-available" }}>
                  <div style={{ display: 'flex', gap: '0.5em' }}>
                    <Avatar size={Avatar.sizes.LARGE} src={val.image} type={Avatar.types.IMG} square ariaLabel="Hadas Fahri" />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <strong>{val.name}</strong>
                      CO2 Offset: {val.coOffset}kg
                      <div>🍐{val.pearsRedeem}</div>
                  </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }} >
                    <div>📜 View Certificate</div>
                    <div>{val.redeemDate}</div>
                  </div>
                </div>
              </ListItem>
            })}



          </List>
        </div>
      </Modal>
    </>
  );
}
