import "./credits.sass";
import React, { useState } from "react";
import { useStateValue } from "../../contexts/stateProvider";
import Modal from "../Modal";

const Credits = ({monday}) => {
  const [{ githubProfileUrl, githubUsername }] = useStateValue();
  let [isOfferOpen, setIsOfferOpen] = useState(false);

  function closeModal() {
    setIsOfferOpen(false);
  }

  function openModal() {
    setIsOfferOpen(true);
  }

  function openOffer(){
    // console.log(selectedColumn.id)
     monday.execute('openPlanSelection', {isInPlanSelection: false});
   
  }

  return (
      <>
    <h5 className="credits__block">
      Upgrade to <span className="credit-pro" onClick={()=>openOffer()}>Pro Version</span>
    </h5>
    <Modal show={isOfferOpen} setClose={()=>closeModal()} title="Plans">
        <p>plans</p>
    </Modal>
    </>
  );
};

export default Credits;
