import './session.sass'
import React, { Component }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useStateValue } from '../../contexts/stateProvider';
import { actionTypes } from '../../reducer';

const Session = () => {

    const [{sessionValue, busyIndicator, timerLabel}] = useStateValue();
    const [state, dispatch] = useStateValue();

    const handleDecrement = () => {
        let updateSessionVal = sessionValue - 1;
        let updateIncProg = 100 / (updateSessionVal*60);
        timerLabel == "Session"? dispatch({
            ...state,
            type: actionTypes.DECREASE_SESSION_VALUE,
            sessionValue: updateSessionVal,
            timerValue: updateSessionVal * 60,
            incProgValue: updateIncProg
        })
       : dispatch({
            ...state,
            type: actionTypes.DECREASE_SESSION_VALUE,
            sessionValue: updateSessionVal
        })
    }
    const handleIncrement = () => {
        let updateSessionVal = sessionValue + 1;
        let updateIncProg = 100 / (updateSessionVal*60);
        timerLabel == "Session" ? dispatch({
            ...state,
            type: actionTypes.INCREASE_SESSION_VALUE,
            sessionValue: updateSessionVal,
            timerValue: updateSessionVal * 60,
            incProgValue: updateIncProg
        })
        : dispatch({
            ...state,
            type: actionTypes.INCREASE_SESSION_VALUE,
            sessionValue: updateSessionVal
        })
    }

    return (
        <div className='session'>
            <span id='session-label' className='session__label'>Session Length</span>
            <div className="session__controls">
                <button
                    type='button'
                    id='session-decrement'
                    className='session__controls--btn'
                    onClick={handleDecrement}
                    disabled={busyIndicator || sessionValue <= 1}
                >
                    <FontAwesomeIcon icon={faMinus}/>
                </button>
                <p id="session-length" className='session__length'>{sessionValue}</p>
                <button
                    type='button'
                    id='session-increment'
                    className='session__controls--btn'
                    onClick={handleIncrement}
                    disabled={busyIndicator || sessionValue > 59}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
        </div>
    )
}

export default Session;
