import React from "react"
import './modal.sass'
export default function Modal({children, show, setClose, title}){
    return(
        <div className="modal-container" style={{display: show ? 'flex' : 'none'}}>
        <section className="modal">
          <header className="modal-header">
            <h2 className="modal-title">{title}</h2>
            {setClose && <div className="modal-close" onClick={setClose}>❌</div>}
          </header>
          <hr style={{borderTop: '0.5px solid gray', width: 'inherit'}}/>
          <div className="modal-content">
            {children}
          </div>
        </section>
      </div>
    )
}