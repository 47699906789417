import "./controls.sass";
import React, { useEffect, useRef } from "react";
import { useClockify } from "../../hooks/useClockify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faRedo } from "@fortawesome/free-solid-svg-icons";
import { useStateValue } from "../../contexts/stateProvider";
import { actionTypes } from "../../reducer";

const Controls = ({task, setNotif}) => {
  const [
    {
      projectName,
      timerValue,
      breakValue,
      sessionValue,
      timerLabel,
      busyIndicator,
      progValue,
    },
  ] = useStateValue();
  const [state, dispatch] = useStateValue();
  // Custom Hook
  const clockifiedValue = useClockify();
  const bellSoundUrl =
    "https://www.soundjay.com/misc/sounds/bell-ringing-05.mp3";
  const audioSoundRef = useRef();

  const handleReset = () => {
    dispatch({
      ...state,
      type: actionTypes.RESET_TIMERS,
    });
    if (busyIndicator) handlePlayPause();
    dispatch({
      ...state,
      type: actionTypes.TOGGLE_TIMER_LABEL,
      timerLabel: "Session",
    });
    dispatch({
      ...state,
      type: actionTypes.START_TIMER,
      timerValue: sessionValue * 60,
      progValue: 0
    });
    audioSoundRef.current.pause();
    audioSoundRef.current.time = 0;
  };

  const handlePlayPause = () => {
    dispatch({
      ...state,
      type: actionTypes.TOGGLE_ISBUSY_INDICATOR,
      busyIndicator: !state.busyIndicator,
    });
    
  };

  const handleCount = () => {
    let updateIncProg = 100 / (sessionValue * 60);
    dispatch({
      ...state,
      type: actionTypes.START_TIMER,
      timerValue: timerValue - 1,
      progValue: progValue + updateIncProg,
    });
    if (timerValue === 0) {
      audioSoundRef.current.play();
      if (timerLabel === "Session") {
        setNotif(10000);
        dispatch({
          ...state,
          type: actionTypes.TOGGLE_TIMER_LABEL,
          timerLabel: "Break",
        });
        dispatch({
          ...state,
          type: actionTypes.START_TIMER,
          timerValue: breakValue * 60,
          progValue: 0
        });
        
      } else {
        dispatch({
          ...state,
          type: actionTypes.TOGGLE_TIMER_LABEL,
          timerLabel: "Session",
        });
        dispatch({
          ...state,
          type: actionTypes.START_TIMER,
          timerValue: sessionValue * 60,
          progValue: 0
        });
      }
      if (busyIndicator) handlePlayPause();
    }
  };

  useEffect(() => {
    if (busyIndicator) {
      let timerInterval = setInterval(() => {
        handleCount();
        document.title = `[${timerLabel}] - ${clockifiedValue[0]}:${clockifiedValue[1]}`;
      }, 1000);
      // Clear interval if the component is unmounted
      return () => clearInterval(timerInterval);
    } else document.title = projectName + "- Grow the Plant, Grow Yourself";
  });

  return (
    <div className="controls__wrp">
      <button type="button" id="start_stop" onClick={handlePlayPause} disabled={!task.hasOwnProperty('id')}>
        <FontAwesomeIcon icon={!busyIndicator ? faPlay : faPause} />
      </button>
      <button type="button" id="reset" onClick={handleReset}>
        <FontAwesomeIcon icon={faRedo} />
      </button>
      <audio id="beep" src={bellSoundUrl} ref={audioSoundRef} preload="auto" />
    </div>
  );
};

export default Controls;
