import React from "react";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css";
//Explore more Monday React Components here: https://style.monday.com/
import AttentionBox from "monday-ui-react-core/dist/AttentionBox.js";
import "./_variables.sass";
import "./index.sass";
import Timer from "./Timer";
// import './index.scss'
import { StateProvider } from "./contexts/stateProvider";
import reducer, { initialState } from "./reducer";
import ThemeProvider from "./contexts/themeProvider";
import Credits from "./components/Credits/Credits";

const monday = mondaySdk();

class App extends React.Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      settings: {},
      name: "",
    };
  }

   
  render() {
    return (
      <div className="App">
        <StateProvider reducer={reducer} initialState={initialState}>
          <ThemeProvider>
            <Timer monday={monday}/>
            <Credits monday={monday} />
          </ThemeProvider>
        </StateProvider>
      </div>
    );
  }
}

export default App;
